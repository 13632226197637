import React, { Component } from 'react';
import { NavLink, Route } from 'react-router-dom';


// import styling 
import './Tools.scss';
import '../Header/Header.scss';
import '../NavBar/Nav.scss';

// import images
import mopolo from '../images/mopolo.jpg';
import logo from '../images/MA-accumetrix-logo.png';


// import application components
import Footer from '../Footer/Footer';
import PaymentAmort from '../PaymentAmort/PaymentAmort';


export default class Tools extends Component {
    render() {
        return (
            <React.Fragment>
             <header className="header__container">
                <img className="header__logo" src={logo} alt="company Logo" width='200' />
                <div className="header__title-container">
                  <h1 className="header__title">Angel Mortgage</h1>
                </div>
                <div className="nav__container">
                    {/* <NavLink className="nav__link" activeClassName="active" to='/Contact Us'>Contact Us</NavLink>
                    <Route path='/Contact Us' component={ContactForm} /> */}
                    {/* <NavLink className="nav__link" activeClassName="active" to='/Mascot'>Mascot</NavLink>
                    <Route path='/Mascot' component={Mascot} /> */}
                    {/* <NavLink className="nav__link" activeClassName="active" to='/Tools'>Tools</NavLink>
                    <Route path='/Tools' component={Tools} /> */}
                    {/* <NavLink className="nav__link" activeClassName="active" to='/InputForm'>Mortgage Rate Estimate</NavLink>
                    <Route path='/InputForm' component={InputForm} /> */}
                    <NavLink className="nav__link" activeClassName="active" to='/Payment Calculator'>Payment Calculator</NavLink>
                    <Route path='/Payment Calculator' component={PaymentAmort} />
                </div>
            </header>
            <div className="results__offer-box">    
                <div className="results__mopolo-offer">
                    <p className="results__mopolo-desc">If you want a handy way to maintain your credit information while staying in touch on your local real estate market, MOPOLO - a mobile product offered by Mortgage Alliance - will help.</p>
                    <a className="results__mopolo-link" href="http://mortgagealliance.com/JohnHeckbert/mopolo" target="blank">
                    <img alt="the M3 MOPOLO logo" src={mopolo} width="380" /></a>
                    <p className="results__mopolo-disc">Clicking on the link will take you to my Mortgage Alliance page.</p>
                </div>
                <div className="results__apply-now-box">    
                    <p className="results__apply-now-desc">If you are ready to take the next step towards achieving your dreams, click the link below and complete our online application. We'll link you up with the best lender for your needs. </p>
                    <div className="results__apply-now-offer">
                        <a className="results__apply-now-button" href="http://mortgagealliance.com/JohnHeckbert/apply-online" target="blank" > Apply Now</a>
                    </div>
                    <p className="results__apply-now-disc">Clicking on the link will take you to my Mortgage Alliance page.</p>
                </div>
            </div>
            <Footer />
            </React.Fragment>
        );
    }
}

