import React from "react";

// import application components
import Header from "../Header/Header";
import Hero from '../Hero/Hero';
import OurStory from '../OurStory/OurStory';
import Footer from '../Footer/Footer';

export default class Home extends React.Component {

    render() {
        return (
          <>
            <Header />
            <Hero />
            <OurStory />
            <Footer />
          </>
        )
    }
}