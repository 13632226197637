import React, { Component } from 'react';
import { NavLink, Route } from 'react-router-dom';

// import logos
import instagram from '../images/instagram_logo.jpeg';
import linkedin from '../images/linkedin.png';
import twitter from '../images/twitter_logo.jpeg';

// import styling
import './Footer.scss';

// import application components
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';

export default class Footer extends Component {
    
    render() {
        return (
            <div className="footer__container">
                <div className="footer__sm-links">
                    <a className="footer__sm-twitter" href="https://twitter.com/jheckbert" target="blank"><img src={twitter} alt="twitter logo" width="25" /></a>
                    <a className="footer__sm-instagram" href="https://instagram.com/johnheckbert" target="blank"><img src={instagram} alt="instagram logo" width="25" /></a>
                    <a className="footer__sm-linkedin" href="https://linkedin.com/in/johnheckbert" target="blank"><img src={linkedin} alt="linkedin logo" width="25" /></a>
                </div>
                <div className="footer__copywrite">
                    <p className="footer__copywrite-text">@ Angel Mortgage 2020</p>
                    <NavLink className="footer__copywrite-link" to='/Privacy Policy'>Privacy Policy</NavLink>
                    <Route path='/Privacy Policy' component={PrivacyPolicy} />

                </div>
                <div className="footer__contact">
                    <a className="footer__contact-phone" href="tel:+1(416)918.6715">Cell: 416.918.6715</a>
                    <a className="footer__contact-email" href="mailto:jheckbert@angelmortgage.ca" target="blank">Email: jheckbert@angelmortgage.ca</a>
                </div>
            </div>
        );
    }
}

