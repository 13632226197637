import React, { Component } from 'react';
import { NavLink, Route } from 'react-router-dom';

// import application components
import Footer from '../Footer/Footer';
import InputForm from '../InputForm/InputForm';
import PaymentAmort from '../PaymentAmort/PaymentAmort';
import ContactForm from '../ContactForm/ContactForm';


// import styling 
import './Mascot.scss';
import '../Header/Header.scss';
import '../NavBar/Nav.scss';


// import images and such
import angelPic from './38219-temp-mascot-640x426.jpg';
import logo from '../images/MA-accumetrix-logo.png';


export default class Mascot extends Component {
    render() {
        return (
            <>
                 <header className="header__container">
              <div className="header__corp">
                <img className="header__corp-logo" src={logo} alt="company Logo" width='200' />
                <p className="header__corp-info">License No 12036</p>
                <p className="header__corp-info2">Independently Owned and Operated Franchise</p>
              </div>
                <div className="mascot__title-container">
                  <h1 className="mascot__header-title">Angel aka Sweet Baby Chonks</h1>
                </div>
                    <div className="nav__container">
                        <div className="nav__apply-now-offer">
                            <a className="nav__apply-now-button" href="http://mortgagealliance.com/JohnHeckbert/apply-online" target="blank" > Apply Now</a>
                        </div>
                        <NavLink className="nav__link" activeClassName="active" to='/Contact Us'>Contact Us</NavLink>
                        <Route path='/Contact Us' component={ContactForm} />
                        {/* <NavLink className="nav__link" activeClassName="active" to='/Mascot'>Mascot</NavLink>
                        <Route path='/Mascot' component={Mascot} /> */}
                        <NavLink className="nav__link" activeClassName="active" to='/InputForm'>Mortgage Rate Estimate</NavLink>
                        <Route path='/InputForm' component={InputForm} />
                        <NavLink className="nav__link" activeClassName="active" to='/Payment Calculator'>Payment Calculator</NavLink>
                        <Route path='/Payment Calculator' component={PaymentAmort} />
                        <a className="nav__mopolo-link" href="http://mortgagealliance.com/JohnHeckbert/mopolo" target="blank">Credit Monitoring Tool</a>
                    </div>
                </header>
                <div className="mascot__container">
                    <p className="mascot__intro">Message to my staff here at Angel Mortgage -</p>
                    <p className="mascot__quote">"Mortgage free means more toys for me!"</p>    
                    <img className="mascot__image" src={angelPic} width="426px" alt="Angel, a fat black cat" />
                    <p className="mascot__question">What does being mortgage free mean for your family?</p>
                    <p className="mascot__invite">For more information on the life and times of Angel, please check out her website at <a href="https://www.sweetbabychonks.com" target='blank'>Sweet Baby Chonks</a>.</p>
                </div>
                <Footer />
            </>
        );
    }
}
