import React, { Component } from 'react';

//import styling
import './NotFound.css';

// import application components
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

class NotFound1 extends Component {
    render() {
        return (
        <>
            <Header />
                <div id="notfound">
                    <div class="notfound">
                        <div class="notfound-404">
                            <h3>Oops! Page not found</h3>
                            <h1><span>4</span><span>0</span><span>4</span></h1>
                        </div>
                        <h2>we are sorry, but the page you requested was not found</h2>
                    </div>
                </div>    
            <Footer />
        </>
        );
    }
}

export default NotFound1;