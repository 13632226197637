import React, { Component } from 'react';

//import styling
import './PrivacyPolicy.scss';

export default class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="privacy__container">
                <h2 className="privacy__title">Privacy Policy</h2>
                <p className="privacy__story paraA">This Web site is protected under the copyright laws of Canada and other countries and Angel Mortgage shall legally enforce its rights concerning the content contained herein. Except for personal or non-commercial use, no person has permission to republish or reproduce the contents in any form or by any means without prior written consent from Angel Mortgare.</p>
                <p className="privacy__story paraA">At Angel Mortgage. we are committed to protecting the privacy and confidentiality of the personal information of our clients (individually, the "Client" and collectively, the "Clients".) As financial service providers, we have a professional obligation to maintain in confidence information we receive within our role as a financial services provider. The purpose of this Privacy Policy is to advise you as to why we ask for personal information, how we use it, what safeguards we employ, and how to contact us with privacy-related matters.</p>
                <p className="privacy__story paraQ">What is personal information?</p>
                <p className="privacy__story paraA">In this Privacy Policy, "Personal Information" means information that is gathered in connection with an application, pre-approval, request for information for a mortgage(s) or loan(s) and insurance or other product through Angel Mortgage or our affiliate and is provided to, or collected by, Angel Mortgage and its staff, agents, associates, and affiliates on Angel Mortgage's behalf.</p>
                <p className="privacy__story paraQ">Why does Angel Mortgage collect personal information?</p>
                <p className="privacy__story paraA">Angel Mortgage collects and uses Personal Information for the following purposes:</p>
                    <ul className="privacy__story-list">
                        <li className="privacy__story-item">Providing mortgage brokering services to the Client and offer products and services best suited to their needs, including arranging and/or renewing loan(s)/mortgage(s), informing the client of group creditor insurance and other products offered or approved by Angel Mortgage or its affiliates that may be of interest to the Client;</li>
                        <li className="privacy__story-item">Checking Client credit worthiness through credit bureau inquiry and personal information agents who have information on a Client's financial position.</li>
                        <li className="privacy__story-item">Auditing the services provided to our Clients, compliance with all municipal, provincial, federal and other applicable laws and other specific purposes which are communicated to the Client by a member of Angel Mortgage before collection of such Personal Information.</li>
                        <li className="privacy__story-item-end">Except when otherwise permitted by law, we will only use a Client's Personal Information for the purposes identified to the Client. When Personal Information is to be used for a purpose not identified, we will take all reasonable steps to ensure that new purpose is identified prior to use.</li>
                    </ul>
                <p className="privacy__story paraQ">How does Angel Mortgage collect personal information?</p>
                <p className="privacy__story paraA">The Client is our main source of information. With the Client's consent, we may also obtain information directly from a third party to help us determine your eligibility for various financial products and services. For credit matters, for example, it is essential that we know your financial worth as well as your credit record. We will also contact other lenders, financial institutions, credit bureaus and employers. Your consent is therefore important because without it, we will not be able to carry out the appropriate inquiries and it might be difficult for us to offer you the credit product you request. In most cases, your consent is given on the forms used for specific products or, from time to time, on a separate form. You will notice that the declaration of consent is clear and unambiguous.</p>
                <p className="privacy__story paraQ">Consent</p>
                <p className="privacy__story paraA">Your provision of Personal Information to Angel Mortgage means that you agree and consent that Angel Mortgage can collect, use and disclose your Personal Information in compliance with this Privacy Policy. Angel Mortgage will not collect, use or disclose a Client's Personal Information without the Client's requisite consent, except in certain extraordinary circumstances. Such extraordinary circumstances shall include without limitation, when legal, medical or security reasons make it impossible or impractical to obtain consent or where it is otherwise in the best interests of the Client.</p>
                <p className="privacy__story papaA">The most common method we use to obtain a Client's consent is by way of the Client's consent as confirmed by the application. Where practicable, we may also obtain a Client's consent through oral communications or other written documentation. Our Clients may withdraw their consent by written notice to us at any time, subject to any legal or contractual restrictions and reasonable notice. A Client's refusal to provide, or subsequent withdrawal of, his or her consent may affect Angel Mortgage's ability to provide the Client with mortgage services. A member of Angel Mortgage will inform the Client of the implications of such withdrawal. To withdraw consent, Client should contact the Chief Privacy Officer in writing at:</p>
                    <ul className="privacy__story-addr">
                        <li className="privacy__story-addr">Angel Mortgage</li>
                        <li className="privacy__story-addr">Attn: Chief Privacy Officer</li>
                        <li className="privacy__story-addr">508 Jack Giles Circle</li>
                        <li className="privacy__story-addr">Newmarket, ON L3X1X9</li>
                    </ul>
                <p className="privacy__story paraQ">Use and disclosure of personal information</p>
                <p className="privacy__story paraA">We will use a Client's Personal Information to provide financial advice and services to the Client, to administer our database, to include Clients in direct marketing activities and for the other uses described above under the heading "Why does Angel Mortgage collect personal information". Under certain circumstances, Angel Mortgage will disclose a Client's personal information to third parties. Specifically, Personal Information may be used, shared and disclosed to, from, or with, the members of Angel Mortgage, witnesses in a litigation proceeding, experts retained on a Client's behalf, private investigators, agents retained on behalf of a Client, credit reporting agencies, credit bureaus, as well as such other third parties as are necessary to facilitate the matter for which the Client has retained our office and provided its consent.</p>
                <p className="privacy__story paraA">Under certain circumstances Angel Mortgage may disclose Personal Information where:</p>
                    <ul className="privacy__story-list">
                        <li className="privacy__story-item">required or authorized by law to do so, for example if a court issues a subpoena;</li>
                        <li className="privacy__story-item">a Client has consented to the disclosure;.</li>
                        <li className="privacy__story-item">when mortgage services by Angel Mortgage are provided to a Client requiring us to give Personal Information about a Client to a third party (for example, to a lender in a real estate mortgage transaction) the Client's consent will be implied, unless the Client tells us otherwise;</li>
                        <li className="privacy__story-item">where it is necessary to pull a credit bureau for purposes of processing a mortgage transaction;</li>
                        <li className="privacy__story-item">if Angel Mortgage engages a third party to provide administrative or support services (such as computer back-up services, shredding or archival file storage) and the third party is bound by our Privacy Policy;</li>
                        <li className="privacy__story-item">protection of Angel Mortgage's best interests;</li>
                        <li className="privacy__story-item">protection of public interests such as the denouncement of criminal activity such as fraud or money laundering. In all such cases, authorities are contacted and personal information may be disclosed or if we retain a law firm(s) to act on our behalf for any reason;</li>
                        <li className="privacy__story-item">if the information is already publicly known.</li>
                    </ul>
                <p className="privacy__story papaA">In addition, if you are a British Columbia Client, your Personal Information will be provided to your Angel Mortgage agent (and a copy retained by Angel Mortgage for purposes consistent with this Privacy Policy) in the event of his or her departure from Angel Mortgage, in order to meet your ongoing financial needs. If you have any questions, or to withdraw your consent to this use, please contact Angel Mortgage's Privacy Officer.</p>
                <p className="privacy__story paraQ">Limiting collection and retention of personal information</p>
                <p className="privacy__story paraA">Angel Mortgage limits the collection of a Client's Personal Information to that which is necessary for the purposes identified in this Privacy Policy as it may be amended from time to time, or for any additional purpose identified to the Client before the collection of the Personal Information. As well, Personal Information is not used or disclosed for purposes other than those for which it was originally collected, except with the consent of the Client or as otherwise permitted by law.</p>
                <p className="privacy__story paraA">Angel Mortgage will retain Personal Information only for so long as it is needed to fulfill the purposes for which it was obtained and to meet our professional requirements as set out our governing regulatory bodies and/or any other legal requirement, statutory or otherwise, from time to time.</p>
                <p className="privacy__story paraQ">Accuracy</p>
                <p className="privacy__story paraA">Angel Mortgage strives to ensure that a Client's Personal Information is as accurate, complete, and up-to-date as is necessary for the purposes for which it is used.</p>
                <p className="privacy__story paraQ">Safeguards</p>
                <p className="privacy__story paraA">Angel Mortgage endeavors to maintain adequate safeguards to protect against loss, theft, unauthorized access, disclosure, copying, use or modification of Clients' Personal Information in the care of the Angel Mortgage. Angel Mortgage uses various methods to safeguard personal information, including physical security, computer and electronic security, destruction of information no longer needed, and contractual protections with third party data processors.</p>
                <p className="privacy__story paraQ">Protection</p>
                <p className="privacy__story paraA">Client lists are never passed on to third parties other than affiliated members of Angel Mortgage. When they are hired, our employees and agents must undertake to respect the confidential nature of client information. Only employees who need to view your file as part of their work have access to this information.</p>
                <p className="privacy__story paraQ">Accountability, Openness and Client Access</p>
                <p className="privacy__story paraA">Angel Mortgage is responsible for the Personal Information under its control and has appointed a Chief Privacy Officer to oversee our efforts to comply in all material respects with applicable privacy legislation and the terms of this Privacy Policy. The Chief Privacy Officer and those designated by the Chief Privacy Officer address and investigate questions or concerns regarding a Client's Personal Information.</p>
                <p className="privacy__story paraA">To reach the Chief Privacy Officer or a member the Privacy Team, please call 416.918.6715 or 647.799.1671.</p>
                <p className="privacy__story paraA">Angel Mortgage will provide a Client access to their Personal Information. The Client may correct or amend any inaccuracies in the Client's Personal Information. Angel Mortgage has the right to refuse a request for access to Personal Information if:</p>
                <ul className="privacy__story-list">
                        <li className="privacy__story-item">The information is protected by legal privilege;</li>
                        <li className="privacy__story-item">Granting access would reveal confidential commercial information;</li>
                        <li className="privacy__story-item">The information was collected for purposes related to the detection and prevention of fraud;</li>
                        <li className="privacy__story-item">The information was generated in the course of a formal dispute resolution process;</li>
                        <li className="privacy__story-item">The information would likely reveal personal information about another individual;</li>
                        <li className="privacy__story-item">The request is vexation or frivolous;</li>
                        <li className="privacy__story-item">To protect our firm's rights and property.</li>
                    </ul>
                <p className="privacy__story paraQ">Communicating With Angel Mortgage</p>
                <p className="privacy__story paraA">We recognize that convenience and quick access to advice must be balanced with security needs. We use many different means to communicate with clients, some of which are more convenient such as e-mail. Our emails are sent with industry standard 128 bit encryption. Such encryption however does not guarantee privacy and security as encrypted messages can nonetheless be subject to interception and translation. If you do not wish us to communicate with you by e-mail, please speak with the consultant/broker/agent handling your matter about alternative arrangements.</p>
                <p className="privacy__story paraQ">Questions</p>
                <p className="privacy__story paraA">If you have questions or comments regarding the management of your personal information, please forward your concerns to</p>
                <ul className="privacy__story-addr">
                        <li className="privacy__story-addr">Angel Mortgage</li>
                        <li className="privacy__story-addr">Attn: Chief Privacy Officer</li>
                        <li className="privacy__story-addr">508 Jack Giles Circle</li>
                        <li className="privacy__story-addr">Newmarket, ON L3X1X9</li>
                        <li className="privacy__story-addr">Tel: 416.918.6715 / 647.799.1671</li>
                    </ul>
            </div>      
        );
    }
}
