import React, { Component } from 'react';

//import styling
import './OurStory.scss';

export default class OurStory extends Component {
    render() {
        return (
            <div className="story__container">
                <p className="story__intro">
                    John's experience includes several years with an independent mortgage brokerage, a stint with a major Canadian bank and most recently as a broker with another national mortgage network. 
                </p>
                <p className="story__continued">
                    Using the rapid debt techniques John employed as a successful financial coach with Canada's Credit Coach, John developed effective strategies to help families pay off their mortgage faster, thereby saving thousand of dollars of unnecessary mortgage interest - with minimal disruption to their lifestyle.
                </p>
                <p className="story__quote">
                    <span className="story__quote-js">"Buying your first home is exciting; owning it free and clear is even more exciting"</span> - John Heckbert
                </p>
                <p className="story__continued">
                    Conventional advisors make recommendations based on their best interpretation of your description, and then leave you on your own to implement them without any guidance on what to expect and things to avoid. Too many times, John has helped families recover from the folly of that approach. Not only does John and his team make recommendations, they commit to working with you and your family as you implement those recommendations. They are there to help you when you run into problems or have questions, guide you in things to avoid and what to expect as you journey toward your financial objectives. You are implementing the recommendations on your own but you are not alone. 
                </p>
                <p className="story__close">
                    Let the Angel Mortgage team help you and your family develop the strategies and action plans you need to accomplish your home ownership goals - decades sooner than you expect. 
                </p>
            </div>
        );
    }
}

