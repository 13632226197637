import React from 'react';

// import styling
import './Header.scss';

// images
import logo from '../images/MA-accumetrix-logo.png';

//import application components
import Nav from '../NavBar/Nav';
// import ApplyNow from './components/NavBar/ApplyNow';
// import Mopolo from './components/NavBar/Mopolo';

export default class Header extends React.Component { 

    render() {
        return (
          <>  
            <header className="header__container">
              <div className="header__corp">
                <img className="header__corp-logo" src={logo} alt="company Logo" width='200' />
                <p className="header__corp-info">License No 12036</p>
                <p className="header__corp-info2">Independently Owned and Operated Franchise</p>
              </div>
                <div className="header__title-container">
                  <h1 className="header__title">Angel Mortgage Team</h1>
                </div>
                <Nav />
            </header>
          </>  
        )
    }
}
