import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';

// import application components
import Mascot from '../Mascot/Mascot';

// import assets
import logo from '../images/angelMortgageLogo.png';

// import styling
import './Hero.scss';

export default class Hero extends Component {
    state = { isActive: false };
    render() {
        return (
            <div className="hero__container"> 
                <div className="hero__story">
                    <p className="hero__story-headline">
                        Creating strong financial foundations, grounded on home ownership, across Ontario as part of an exciting mortgage brokerage - <span className="hero__story-co" >Mortgage Alliance Accumetrix</span> - the Angel Mortgage team is driven by a single purpose - to help Canadian families own their homes decades sooner. Building on 3 years as a successful credit coach with Canada's Credit Coach, the team helps families eliminate their debt through effective money management techniques and rapid debt reduction strategies. 
                    </p>
                        <NavLink className="hero__story-link" to='/Mascot'>Click to learn more about John's Angel Mortgage Team and Mission</NavLink>
                        <Route path='/Mascot' component={Mascot}></Route>
                </div>
                <div className="hero__image">
                    <img className="hero__myPic" src={logo} alt="just me" width="200" />
                </div>
            </div>
        );
    }
}

