// change history 
// 200504 - added access-control-allow-option to route (cors issue)
//
import React, { Component } from 'react';
import axios from 'axios';
import { NavLink, Route } from 'react-router-dom';

//import styling
import './PaymentAmort.scss';
import '../Header/Header.scss';
import '../NavBar/Nav.scss';

// images
import logo from '../images/MA-accumetrix-logo.png';

// import application components
import Footer from '../Footer/Footer';
import InputForm from '../InputForm/InputForm';
import ContactForm from '../ContactForm/ContactForm';
import Mascot from '../Mascot/Mascot';


export default class PaymentAmort extends Component {
    state = {
        userDidFinish: false
      }

    calcPayAmort = (event) => {
        event.preventDefault();
        let amount = event.target.mortAmt.value;
        let rate = event.target.mortRate.value;
        let amortization = event.target.mortAmort.value;
        let term = event.target.mortTerm.value;

        // let hostName = process.env.REACT_APP_SERVERIP;

        axios
            .post('https://www.heckbert.info:7400/payAmort', {
            // .post('https://localhost:7400/payAmort', {

                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                },
                body: { 
                    mortAmt: amount,
                    mortRate: rate,
                    mortAmort: amortization,
                    mortTerm: term
                }
            })
            .then(result => {
                let data = result.data
                console.log('response is:', data)
                this.setState({
                    data :  data,
                    userDidFinish: true 
                });
                let clearPayment = document.querySelector('.payAmort__payment-field');
                clearPayment.value = data[10];
                let clearAmount = document.querySelector('.payAmort__amount-field');
                clearAmount.value = " ";
                let clearRate = document.querySelector('.payAmort__rate-field');
                clearRate.value = " ";
                let clearAmort = document.querySelector('.payAmort__amort-field');
                clearAmort.value = " ";
                let clearTerm = document.querySelector('.payAmort__term-field');
                clearTerm.value = " ";

            })
            .catch(err => {
                console.log(err);
            });
    };
  

    render() {
        return (
        <>
            <header className="header__container">
                <div className="header__corp">
                    <img className="header__corp-logo" src={logo} alt="company Logo" width='200' />
                    <p className="header__corp-info">License No 12036</p>
                    <p className="header__corp-info2">Independently Owned and Operated Franchise</p>
                </div>
                    <div className="header__title-container">
                    <h1 className="header__title">Payment Calculator</h1>
                </div>
                <div className="nav__container">
                    <div className="nav__apply-now-offer">
                        <a className="nav__apply-now-button" href="http://mortgagealliance.com/JohnHeckbert/apply-online" target="blank" > Apply Now</a>
                    </div>
                    <NavLink className="nav__link" activeClassName="active" to='/ContactForm'>Contact Us</NavLink>
                    <NavLink className="nav__link" activeClassName="active" to='/Mascot'>Mascot</NavLink>
                    <NavLink className="nav__link" activeClassName="active" to='/InputForm'>Mortgage Rate Estimate</NavLink>
                    <NavLink className="nav__link" activeClassName="active" to='/PaymentAmort'>Payment Calculator</NavLink>
                    <a className="nav__mopolo-link" href="http://mortgagealliance.com/JohnHeckbert/mopolo" target="blank">Credit Monitoring Tool</a>
                    <Route path='/InputForm' component={InputForm} />
                    <Route path='/PaymentAmort' component={PaymentAmort} />
                    <Route path='/ContactForm' component={ContactForm} />
                    <Route path='/Mascot' component={Mascot} />
                </div>
            </header>
            <section className="payAmort__intro">
                <p>Please enter the required information and select the option below.  </p>
            </section>
            <form className="payAmort__form" id="payAmort" onSubmit={this.calcPayAmort}>
                <div className="payAmort__form-amount">
                    <label className="payAmort__amount-label" htmlFor="amount">Mortgage Amount</label>
                    <input className="payAmort__amount-field" type="number" name="mortAmt" id="mortAmt"></input>
                </div>
                <div className="payAmort__form-rate">
                    <label className="payAmort__rate-label" htmlFor="rate">Interest Rate</label>
                    <input className="payAmort__rate-field" type="integer" name="mortRate" id="mortRate"></input>
                </div>
                <div className="payAmort__form-amort">
                    <label className="payAmort__amort-label" htmlFor="amort">Amortization required</label>
                    <input className="payAmort__amort-field" type="number" name="mortAmort" id="mortAmort"></input>
                </div>
                <div className="payAmort__form-term">
                    <label className="payAmort__term-label" htmlFor="mortTerm">Term Requested</label>
                    <div className="payAmort__term-field">
                        <input type="radio" id="mortTerm1" name="mortTerm" value="1" />
                        <label htmlFor="1">1 yr</label>
                        <input type="radio" id="mortTerm2" name="mortTerm" value="2" />
                        <label htmlFor="2">2 yr</label>
                        <input type="radio" id="mortTerm3" name="mortTerm" value="3" />
                        <label htmlFor="3">3 yr</label>
                        <input type="radio" id="mortTerm4" name="mortTerm" value="4" />
                        <label htmlFor="4">4 yr</label>
                        <input type="radio" id="mortTerm5" name="mortTerm" value="5" />
                        <label htmlFor="5">5 yr</label>
                        <input type="radio" id="mortTerm6" name="mortTerm" value="7" />
                        <label htmlFor="7">7 yr</label>
                        <input type="radio" id="mortTerm7" name="mortTerm" value="10" />
                        <label htmlFor="10">10yr </label>
                    </div>
                </div>
                <div className="payAmort__button-container">
                    {/* <button className="payAmort__button-payment button">Calculate payment options</button> */}
                    <button className="payAmort__button-amort button" >Calculate payment and amortization table </button>
                    {/* <button className="payAmort__button-amort button">Print amortization table </button>  */}
                </div>
            </form>
            <form className="payAmort__payment-container">
                <div className="payAmort__form-payment">
                    <label className="payAmort__payment-label" htmlFor="amount">Payment Amount</label>
                    <input className="payAmort__payment-field" type="number" name="mortPay" id="mortPay" ></input>
                </div>
            </form>
            <Footer />
        </>
        );
    }
}

