// change history 
// 200504 - added access-control-allow-option to route (cors issue)
//
import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import axios from "axios";
import {Helmet} from 'react-helmet';

// import application components
import InputForm from "./components/InputForm/InputForm";
import ResultsPage from "./components/ResultsPage/ResultsPage";
import Home from './components/Home/Home';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Mascot from './components/Mascot/Mascot';
import Hero from './components/Hero/Hero';
import PaymentAmort from './components/PaymentAmort/PaymentAmort';
import Tools from './components/Tools/Tools.js';
// import ApplyNow from './components/NavBar/ApplyNow';
// import Mopolo from './components/NavBar/Mopolo';
import NotFound from './components/NotFound/NotFound';
import ContactForm from './components/ContactForm/ContactForm';

export default class App extends React.Component {
  state = {
    userDidFinish: false
  }

  analyzeForm = (event) => {
    event.preventDefault();
    let appName = event.target.name.value;
    let appEmail = event.target.email.value;
    let appMortgage = event.target.mortgage.value;
    let appEquity = event.target.equity.value;
    let appSelfInc = event.target.selfIncome.value;
    let appSelfCredit = event.target.selfCredit.value;
    let appPartInc = event.target.partIncome.value;
    let appPartCredit = event.target.partCredit.value;
    let appEmpInd = event.target.selfEmpInd.value;
    let appSecurity = event.target.selfSecurity.value;
    let data = " ";
    
    let hostName = process.env.REACT_APP_SERVERIP;
    // TODO: 'remove the account key before production build';

    axios
      .post('https://www.heckbert.info:7400/analyze', {
      // .post('https://localhost:7400/analyze', {

        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        },
        data: {
          name: appName,
          email: appEmail,
          mortgageAmt: appMortgage,
          equity: appEquity,
          selfIncome: appSelfInc,
          selfCredit: appSelfCredit,
          partIncome: appPartInc,
          partCredit: appPartCredit,
          empInd: appEmpInd,
          securityAsset: appSecurity
        }
      })
      .then(result => {
          data = result.data
          console.log('result is:', data)
          this.setState({
            data :  data,
            userDidFinish: true 
          });

      })
      .catch(err => {
        console.log(err);
      });
    };
  
  render() {
    return (
      <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Angel Mortgage</title>
        </Helmet>
        <BrowserRouter>
          <Switch>
            <Route exact path='/'><Home /></Route>
            <Route path='/InputForm' render={(props) => <InputForm analyzeForm={this.analyzeForm} {...props}/>}></Route>
            <Route path='/ResultsPage' render={() => <ResultsPage data={this.state.data}></ResultsPage>}></Route>
            <Route path='/Mascot'><Mascot /></Route>
            <Route path='/Contact Us'><ContactForm /></Route>
            <Route path='/Privacy Policy'><PrivacyPolicy /></Route>
            <Route path='/Hero'><Hero /></Route>
            <Route path='/Payment Calculator'><PaymentAmort /></Route>
            <Route path='/Tools'><Tools /></Route>
            <Route path='/NotFound'><NotFound /></Route>
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}
